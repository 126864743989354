import { ChangeEvent, useState } from 'react';
import { IEditPractice } from '../../../interface/Practice';


interface SqcnTabViewModelProps {
    editPracticeData: IEditPractice | null;
    setEditPracticeData: (editPracticeData: IEditPractice) => void;
}

export const useSqcnTabViewModel = ({ editPracticeData, setEditPracticeData }: SqcnTabViewModelProps) => {
    const [selectedDropdownValue, setSelectedDropdownValue] = useState<string>('1');

    const mcKessons = [
        {
          text: "McKesson Option 1",
          value: "1"
        },
        {
          text: "McKesson Option 2",
          value: "2"
        }
      ];

    const handleDateChange = (date: Date, name: string) => {
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                practiceSqcn: {
                    ...editPracticeData.practiceSqcn,
                    [name]: date,
                },
                [name]: date,
            });
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                practiceSqcn: {
                    ...editPracticeData.practiceSqcn,
                    [name]: value,
                },
            });
        }
    };

    const handleCheckboxInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                practiceSqcn: {
                    ...editPracticeData.practiceSqcn,
                    [name]: checked,
                },
            });
        }
    };

    //ToDo: Need to implement the dropdown change handler
    const sqnDropdownChangeHandler = (event: ChangeEvent<HTMLSelectElement>, name: string) => {
        const { value } = event.target;
        setSelectedDropdownValue(value);
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                practiceSqcn: {
                    ...editPracticeData.practiceSqcn,
                    [name] : Number(value),
                },
            });
        }
    }

    return {
        mcKessons,
        editPracticeData,
        selectedDropdownValue,
        sqnDropdownChangeHandler,
        handleDateChange,
        handleInputChange,
        handleCheckboxInputChange,
    };
};