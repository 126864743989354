import React from 'react';
import './toggleSwitch.scss';

interface ToggleSwitchProps {
    selected: boolean;
    onToggle: (value: boolean, name: string) => void;
    name: string;
    optionLabels: {
        trueOption: string;
        falseOption: string;
    };
    dataTestId?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ selected, onToggle, name, optionLabels, dataTestId }) => {
    const handleChange = (value: boolean) => {
        console.log(value,"for toggole value");
        onToggle(value, name);
    };

    const yesInputId = `${name}-yes`;
    const noInputId = `${name}-no`;

    return (
        <fieldset className="toggle-switch" data-testid={dataTestId}>
            <div className="toggle">
                <input
                    type="radio"
                    id={yesInputId}
                    name={name}
                    value="yes"
                    checked={selected}
                    onChange={() => handleChange(true)}
                />
                <label
                    htmlFor={yesInputId}
                    className={selected ? 'active' : ''}
                >
                   {optionLabels.trueOption}
                </label>
                <input
                    type="radio"
                    id={noInputId}
                    name={name}
                    value="no"
                    checked={!selected}
                    onChange={() => handleChange(false)}
                />
                <label
                    htmlFor={noInputId}
                    className={!selected ? 'active' : ''}
                >
                   {optionLabels.falseOption}
                </label>
            </div>
        </fieldset>
    );
};

export default ToggleSwitch;