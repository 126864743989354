import { useEffect, useState } from 'react';
import { ModalOverlayWithoutClose, Button } from '@sentaraui/optimahealth_web';
import { IStaff } from '../interface/staff';
import { LocationContainer } from './location/locationContainer';
import { StaffEditPage } from './edit/staffEditPage';
import './staffScreen.scss';
import { useRemoveStaffMutation } from '../services/staffApi';
import ConfirmationModal from '../modal/confirmationModal';
import { NewPage } from './new/newPage';
import { Practice } from '../interface/PracticesLists';
import Table from '../common/Table/table';
import ToastNotification from '../common/toast/toastNotification';
import { HandleErrorMessage } from '../common/errorMessage';

interface IStaffListProps {
  practiceId: number | null;
  searchResults: any;
  selectedPractice: Practice | null;
}

const StaffList = ({ searchResults, practiceId, selectedPractice }: IStaffListProps) => {
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState({ staffIndex: -1 });
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);
  const [selectedStaff, setSelectedStaff] = useState<IStaff>({} as IStaff);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [removeStaff] = useRemoveStaffMutation();

  useEffect(() => {
    if (searchResults.length > 0) {
      setIsRowSelected(false);
      setSelectedRowIndex({ staffIndex: -1 });
    }
  }, [searchResults]);
  const handleLocationsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!selectedRowIndex) {
      return;
    }
    const { staffIndex } = selectedRowIndex;
    const staff = searchResults?.staff[staffIndex];
    setModalContent(<LocationContainer
      name={staff?.name}
      staffId={staff?.personId}
      practiceId={staff?.practiceId}
      practiceName={searchResults?.practiceName}
      onClose={() => setIsModalOpen(false)}
    />);
    setIsModalOpen(true);
  };
  
  const handleRemovesClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsRemove(true)
  }

  const onCancelHandler = () => {
    setIsRemove(false);
  }

  const onConfirmHandler = async () => {
      try {
        const response  = await removeStaff({
          practiceId: practiceId ?? 0,
          locationId: 0,
          staffId: selectedStaff.personId,
        }).unwrap();
       
        if(response) {
          ToastNotification('success', `Successfully removed ${selectedStaff.firstName} ${selectedStaff.lastName}`);
          
        } else {
          ToastNotification('error', `user not removed ${selectedStaff.firstName} ${selectedStaff.lastName}`);
        }
      } 
      catch (error: unknown) {
        if (error instanceof Error) {
            HandleErrorMessage(error, 'remove staff');
        } else {
            HandleErrorMessage(new Error('An unknown error occurred'), 'remove staff');
        }
      }finally {
        setIsRemove(false);
        setIsModalOpen(false);
      }
  };

  const handleRowSelect = (newStaffIndex: number, staff: IStaff) => {
    let rows = document.getElementsByClassName("inner-table")[0].getElementsByTagName("tr");
    for(let i=0; i< rows.length;i++)  {
      if(i===newStaffIndex && rows.item(i) !== null)
        highlightTableRow(rows[newStaffIndex+1]);
    };
    setIsRowSelected(true);
    setSelectedStaff(staff);
    setSelectedRowIndex({ staffIndex: newStaffIndex });
  };
  function highlightTableRow(row: any) {
    // Remove highlight from previously selected row, if any
    const previouslySelectedRow = document.querySelector('tr.selected');
    if (previouslySelectedRow) {
      previouslySelectedRow.classList.remove('selected');
    }
    // Highlight the clicked row
    row.classList.add('selected');
  }

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!selectedRowIndex) {
      return;
    }
    const { staffIndex } = selectedRowIndex;
    const staff = searchResults?.staff[staffIndex];

    setModalContent(<StaffEditPage
      name={staff?.name}
      staffId={staff?.personId}
      practiceId={staff?.practiceId}
      practiceName={searchResults.practiceName}
      selectedStaff={selectedStaff}
      onClose={() => setIsModalOpen(false)}
    />);
    setIsModalOpen(true);
  }
  const headersMapping = [
    { key: 'lastName', text: 'Last Name', dataTestId: 'staff-list-row-1' },
    { key: 'firstName', text: 'First', dataTestId: 'staff-list-row-2' },
    { key: 'npi', text: 'NPI', dataTestId: 'staff-list-row-3' },
    { key: 'title', text: 'Title', dataTestId: 'staff-list-row-4' }
  ];
  return (
    <div>
      <div className='table-container'>
        <Table
          className="inner-table"
          header={Object.values(headersMapping).map(header => ({
            text: header.text
          }))}
          rows={searchResults?.staff?.map((staff: any, staffIndex: number) =>
            headersMapping.map(header => ({
              text: (
                <td
                  data-testid={header.dataTestId}
                  onClick={() => handleRowSelect(staffIndex, staff)}
                  onKeyDown={() => {}}
                >
                  {staff[header.key]}{" "}
                </td>
              )
            }))
          )}
          rowClickHandler={(rowIndex) => {
            const selectedStaff = searchResults?.staff?.[rowIndex];
            if (selectedStaff) {
              handleRowSelect(rowIndex, selectedStaff);
            }
          }}
        />
      </div>
      {searchResults?.staff &&
        <div >
          <hr />
          <div className='button-wrapper'>
            <Button
              className="staff-list-button"
              id="primary"
              data-testid="staff-edit-button"
              onClick={handleEditClick}
              disabled={!isRowSelected}
            >Edit</Button>

            <Button
              className="staff-list-button"
              id="primary"
              data-testid="staff-locations-button"
              onClick={handleLocationsClick}
              disabled={!isRowSelected}
            >Locations</Button>

            {practiceId !== null && practiceId > 0 &&
              <Button
                className="staff-list-button"
                id="primary"
                data-testid="staff-removal-button"
                onClick={handleRemovesClick}
                disabled={!isRowSelected}
              >Remove</Button>
            }

            <ModalOverlayWithoutClose isOpen={isModalOpen}
              disabled={!isRowSelected}
              onClose={() => setIsModalOpen(false)}
              data-testid="modal-overlay-close"
              className="modal-container loc-modal-w-large">
              {modalContent}
            </ModalOverlayWithoutClose>
            <ModalOverlayWithoutClose
              isOpen={isRemove}
              onClose={onCancelHandler}
              data-testid="modal-remove-overlay-close"
              className="modal-container loc-modal-small"
            >
              <ConfirmationModal
                header={'Confirm Remove'}
                message={`Are you sure you want to remove ${selectedStaff?.firstName} ${selectedStaff?.lastName}?`}
                onConfirm={onConfirmHandler}
                onCancel={onCancelHandler}
                confirmText={'Yes'}
                cancelText={'No'} />
            </ModalOverlayWithoutClose>
          </div>
        </div>

      }
    </div>
  );
}


export default StaffList;