import { useState } from 'react';
import { IPracticeStaffMember } from '../../../interface/staff';

const useLanguageViewModel = (staffId: number, practiceStaffMember: IPracticeStaffMember | null) => {
    const [isVisible, setIsVisible] = useState(false);
    const [triggerQuery, setTriggerQuery] = useState(false);
    const languageData =practiceStaffMember?.physicianInfo.languages;
    const handlePopUp = () => {
        setIsVisible(!isVisible);
        if (!isVisible) {
            setTriggerQuery(true);
            console.log('triggerQuery', triggerQuery);
        }
    };

    return {
        languageData,
        isVisible,
        handlePopUp
    };
};

export default useLanguageViewModel;
