import { ChangeEvent, useEffect, useState } from "react";
import { IEditPractice } from "../../../interface/Practice";
import { useGetSuperGroupsQuery } from "../../../services/practicesApi";
import { SuperGroupItem } from "../../../interface/PracticesLists";

interface EditPracticeViewModelProps {
    editPracticeData: IEditPractice | null;
    setEditPracticeData: (editPracticeData: IEditPractice) => void;
}
export const useEditPracticeViewModel = ({ editPracticeData, setEditPracticeData }: EditPracticeViewModelProps) => {
    const { data } = useGetSuperGroupsQuery();
    const [practiceType, setPracticeType] = useState<string>('1');
    const [selectedSuperGroup, setSelectedSuperGroup] = useState<number | undefined>(undefined);
    const [superGroupData, setSuperGroupData] = useState<SuperGroupItem[]>([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const superGroups = data ?? [];
    
     // ToDo: Need to get the practice types from the API
     const practiceTypes = [
        { value: '1', label: 'Hospital' },
        { value: '2', label: 'Practice' },
        { value: '3', label: 'PhysicianOffice' },
        { value: '4', label: 'UrgentCareCenter' },
        { value: '5', label: 'Billing' },
    ];

    useEffect(()=>{
         // Check if superGroups has items
        if(superGroups?.length){
            const firstSuperGroup = { ...superGroups[0] };
             // Assign 'No Assignment' to the first item's name if it's empty
        if (firstSuperGroup.superGroupName === '') {
            firstSuperGroup.superGroupName = 'No Assignment';
        }
        // Update state with the potentially modified super group data
        setSuperGroupData([firstSuperGroup, ...superGroups.slice(1)]);
    }
}, [superGroups]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (editPracticeData){
            setEditPracticeData({
                ...editPracticeData,
                [name]: value
            });
        }
    };

    //ToDo: Need to verfiy this with backend api
    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedGroupId = Number(e.target.value);

         // Update the selected super group ID state
        setSelectedSuperGroup(selectedGroupId);

        // Find the selected super group based on the selected ID
        const selectedSuperGroup = superGroupData?.find(group => group.superGroupId === selectedGroupId);
        
        // Update the edit practice data with the selected super group's details
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                superGroupId  : selectedGroupId,
                superGroupName: selectedSuperGroup?.superGroupName ?? '',
            });
        }
    };   

    const handlePracticeTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setPracticeType(e.target.value);
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                practiceType: e.target.value
            });
        }
    };
    return {
        practiceTypes,
        editPracticeData,
        practiceType,
        selectedSuperGroup,
        superGroupData,
        handleInputChange,
        handleSelectChange,
        handlePracticeTypeChange,
    };
}