import React, { Dispatch, useState } from 'react';
import { Input, Button, Label, SelectDropdown, ModalOverlay,Table } from "@sentaraui/optimahealth_web";
import './addPracticeModalStyles.scss'
import { ILocation } from '../models/location/location';
import LocationComponent from '../location/locationComponent';
import { useGetSuperGroupsQuery, useAddPracticeMutation } from '../services/practicesApi';
import { IEditPractice } from '../interface/Practice';
import ToastNotification from '../common/toast/toastNotification';
import { initialLocationState } from '../../constants/constants';
import { HandleErrorMessage } from '../common/errorMessage';

interface Props {
    closeModal: () => void; // Function to close the modal
    setFire: Dispatch<React.SetStateAction<boolean>>; // Function to set the fire state
    fire: boolean; // Fire state to trigger the useEffect
}

const AddPracticeModal: React.FC<Props> = ({ closeModal, setFire, fire }) => {
    const { data } = useGetSuperGroupsQuery();
    const superGroupData = data ?? [];
    const [name, setName] = useState<string>('');
    const [taxID, setTaxID] = useState<string>('');
    const [vendorIds, setVendorIds] = useState<string>('');
    const [selectedSuperGroup, setSelectedSuperGroup] = useState<number | undefined>(undefined);
    const [practiceType, setPracticeType] = useState<string>('1');
    const [selectedLocationDetails, setSelectedLocationDetails] = useState<ILocation>(initialLocationState);
    const [isLocationModalOpen, setIsLocationModalOpen] = useState<boolean>(false);
    const [locations, setLocations] = useState<ILocation[]>([]);
    const [addPractice] = useAddPracticeMutation();
    const [nameError, setNameError] = useState<string>('');
    const [venderError, setVenderError] = useState<string>('');
    
    const savePractice = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!name) {
            setNameError('Name is required');
            return;
        }
        
        const practice: IEditPractice = {
            practiceID: 0,
            practiceType: practiceType,
            name: name,
            description: "",
            taxID: taxID,
            vendorIds: vendorIds,
            suppressSentaraDotCom: false,
            isAdminAD: false,
            superGroupId: selectedSuperGroup ?? 0,
            superGroupName: "",
            locations: locations,
            superGroups:[],
            practiceSqcn: {
                mcKessonOrgId: '',
                mcKessons: [],
                mcKesson: null,
                emrListItems: [],
                sqcnEmrId: null,
                deactivationListItems: [],
                activationDate: null,
                deactivationDate: null,
                claimStatus: false,
                affiliate: false,
                edI837Status: false,
                emrStatus: false,
                deferred: false
            },
        };

        
        if (practice.vendorIds !== '' && practice.vendorIds.length < 6) {
            setVenderError('VenderId should be grater than equal to 6 digit');
            return;
        }

        // Post the practice object to the server
        try {
            const response:any = await addPractice(practice).unwrap();
            ToastNotification("success", response?.result);
            setFire(false);
        } catch (error: unknown) {
            if (error instanceof Error) {
                HandleErrorMessage(error, 'add practice');
            } else {
                HandleErrorMessage(new Error('An unknown error occurred'), 'add practice');
            }
        } finally {
           closeModal();
        }
    };

    const handleLocationModalOpen = () => {
        setIsLocationModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsLocationModalOpen(false);
    };

    const handleLocationSave = (location: ILocation) => {
        setLocations(prevLocations => [...prevLocations, location]);
    };

    const practiceTypes = [
        { value: '1', label: 'Hospital' },
        { value: '2', label: 'Practice' },
        { value: '3', label: 'Physician Office' },
        { value: '4', label: 'Urgent Care Center' },
        { value: '5', label: 'Billing' },
    ];

    return (
        <div className="practice-container">
            <div className="practice-header" id='title'>
            <span>New Practice</span>
            <button id='btn-close' onClick={closeModal}>x</button>
            </div>
            <form onSubmit={savePractice} className="form-container">
                <div className="form-group">
                    <Label className="label">
                        Practice Type
                        <SelectDropdown
                            value={practiceType}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setPracticeType(e.target.value)}
                            className="dropdown"
                        >
                            {practiceTypes.map(type => (
                                <option key={type.value} value={type.value}>
                                    {type.label}
                                </option>
                            ))}
                        </SelectDropdown>
                    </Label>
                    <Label className="label">
                        Super Group
                        <SelectDropdown
                            value={selectedSuperGroup}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                const selectedGroupId = Number(e.target.value);
                                setSelectedSuperGroup(selectedGroupId);
                            }}
                            className="dropdown"
                        >
                            {superGroupData.map((group) => (
                                <option key={group.superGroupId} value={group.superGroupId}>
                                    {group.superGroupName}
                                </option>
                            ))}
                        </SelectDropdown>
                    </Label>

                </div>
                <div className="form-group">
                    <Label className="label">
                        Name
                        <Input
                            type="text"
                            value={name}
                            placeholder="Name"
                            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => {
                                setName(e.target.value);
                                setNameError(''); // Clear the error when the field is changed
                            }}
                            className="input"
                        />
                        {nameError && <div className="error">{nameError}</div>}
                    </Label>
                    <Label className="label">
                        Tax ID
                        <Input
                            type="text"
                            value={taxID}
                            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setTaxID(e.target.value)}
                            className="input"
                            placeholder="Tax Id"
                        />
                    </Label>
                    <Label className="label">
                        Vendor IDs
                        <Input
                            type="text"
                            value={vendorIds}
                            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => 
                                {
                                    setVendorIds(e.target.value);
                                    setVenderError(''); 
                                }}
                            className="input"
                            placeholder="Vendor Id"
                        />
                        {venderError && <div className="error">{venderError}</div>}
                    </Label>
                </div>
                <div className="custom-border">
                    <h2 className="header-center">Locations</h2>
                    <div className="practice-button-container">
                        <Button
                            type="button"
                            onClick={handleLocationModalOpen}
                            className="save-button"
                            data-testid="add-location-button" // Add this line
                        >
                            Add...
                        </Button>
                    </div>
                    <div className="table-container-practice">
                        <Table
                            className="sc-NxrB"
                            header={[
                                { text: 'Location Name' },
                                { text: 'Address' },
                                { text: 'City' },
                                { text: 'State' }
                            ]}
                            rows={locations.map(location => [
                                { text: <div className="text-center">{location.name}</div> },
                                { text: <div className="text-center">{location.address1}</div> },
                                { text: <div className="text-center">{location.city}</div> },
                                { text: <div className="text-center">{location.state}</div> },
                            ])}
                        />
                    </div>
                </div>
                <div className="practice-button-container">
                    <button
                        data-testid="submit-button-practice"
                        type="submit"
                        className="save-button"
                        name="Save">Save</button>
                </div>
            </form>

            {isLocationModalOpen && (
                <ModalOverlay isOpen={isLocationModalOpen} onClose={handleCloseModal}>
                    <div className='location-editor-wrapper'>
                        <Label className="location-editor-label">
                            Location Editor
                        </Label>
                        </div>
                    <LocationComponent
                        isEdit={false}
                        selectedLocationDetails={selectedLocationDetails}
                        setSelectedLocationDetails={setSelectedLocationDetails}
                        handleClose={handleCloseModal}
                        isTemporarySave={true} // Enable temporary save functionality
                        onLocationSave={handleLocationSave} // Pass handleLocationSave as a prop
                        setFire={() => {}} // Pass an empty function as a prop
                        fire={fire} // Pass false as a prop
                    />
                </ModalOverlay>
            )}
        </div>
    );
};

export default AddPracticeModal;