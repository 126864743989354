import { useEffect, useState } from 'react';
import { IUploadSelectedImage } from '../../interface/staff';
import { useUploadImageHook } from './useUploadImageHook';
import ToastNotification from '../../common/toast/toastNotification';

export function useImageViewModel(staffId: number, photo: string) {
    const [imageUploadSuccess, setImageUploadSuccess] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string>(photo);
    const [disableUpload, setDisableUpload] = useState(true);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const {uploadPicture} = useUploadImageHook(setIsSpinner);
    const [imageRestored, setImageRestored] = useState<boolean>(false);

    useEffect(() =>{
        if(photo !== null && photo !==  '')
        {
            setSelectedImage(photo);
        }
    },[photo])
    const handleUploadClick = (type: string, event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let formData: IUploadSelectedImage;
        try {
            switch (type) {
                case 'upload':
                    formData = {
                        Id: staffId,
                        imageData: selectedImage
                    };
                    setIsSpinner(true);
                    uploadPicture(formData);
                    if (selectedImage !== formData.imageData) {
                        setSelectedImage(formData.imageData);
                    }
                    setImageUploadSuccess(true);
                    setDisableUpload(true);
                    break;
                case 'delete':
                    formData = {
                        Id: staffId,
                        imageData: 'DELETE'
                    };
                    setIsSpinner(true);
                    uploadPicture(formData);
                    setSelectedImage("");
                    setImageUploadSuccess(false);
                    setDisableUpload(true);
                    break;
                case 'restore':
                    setIsSpinner(true);
                    setSelectedImage("");
                    setImageUploadSuccess(false);
                    setImageRestored(true);
                    break;
            }

        } catch (error) {
            console.error('Error while uploading image', error);
            setImageUploadSuccess(false)

        }

    };
    
    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] ?? null;
        const maxSizeInBytes = 720 * 1024;

        if (!file) return;

        // Validate file type and size
        if (
            (!file.type.startsWith('image/jpeg') && !file.type.startsWith('image/png')) ||
            file.size > maxSizeInBytes
        ) {
            ToastNotification('error', 'Only JPEG or PNG files with a maximum size limit of 720KB are allowed.');
            e.target.value = '';
            return;
        }

        const safeBlobUrl = URL.createObjectURL(file);
        const img = new Image();

        img.onload = () => {
            if (img.width > 720 || img.height > 720) {
                showError('Image dimensions cannot exceed 720px.');
            } else {
                handleImageUpload(safeBlobUrl, file);
            }
        };

        img.onerror = () => {
            showError('Only image files are allowed.');
        };

        img.src = safeBlobUrl;
        setDisableUpload(false);
    };

    const handleImageUpload = (safeBlobUrl: string, file: File) => {
        fetch(safeBlobUrl, { mode: 'no-cors' })
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64Data = reader.result;
                    const filteredBase64Data = filterBase64Data(base64Data as string);
                    if (filteredBase64Data) {
                        setSelectedImage(filteredBase64Data);
                    } else {
                        showError('Unsupported image format.');
                    }
                };
            })
            .finally(() => {
                URL.revokeObjectURL(safeBlobUrl);
            });
    };

    const filterBase64Data = (base64Data: string) => {
        if (base64Data.startsWith('data:image/jpeg;base64,')) {
            return base64Data.split('data:image/jpeg;base64,')[1];
        } else if (base64Data.startsWith('data:image/png;base64,')) {
            return base64Data.split('data:image/png;base64,')[1];
        }
        return '';
    };

    const showError = (message: string) => {
        ToastNotification('error', message);
        setSelectedImage('');
        setDisableUpload(true);
    };

    return {
        imageUploadSuccess,
        disableUpload,
        setImageRestored,
        imageRestored,
        handleUploadClick,
        handleImageChange,
        setDisableUpload,
        setSelectedImage,
        selectedImage,
        uploadPicture,
        setImageUploadSuccess,
        isSpinner
    };
}

