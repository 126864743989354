import React, { useEffect, useState } from 'react';
import './clinicianInfoStyles.scss';
import {
    Input,
    Label,
    SelectDropdown,
    Checkbox,
    Loader
} from "@sentaraui/optimahealth_web";
import { IPracticeStaffMember } from '../../interface/staff';
import UploadImage from './uploadImage';

interface IClinicianInfoProps {
    practiceStaffMember: IPracticeStaffMember | null;
    staffId: number;
}
const ClinicianInfo: React.FC<IClinicianInfoProps> = ({
    practiceStaffMember,
    staffId,
}) => {

    const [isSpinner, setIsSpinner] = useState(true);

    useEffect(() => {
        if (practiceStaffMember) {
            setIsSpinner(false);
        }
    }, [practiceStaffMember]);

    return (
        <div className='manin-clinician-wrapper'>
             {isSpinner && <Loader />}
            {!isSpinner && (
                <div className='clinician-info-wrapper'>
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Name:
                        </Label>
                        <Input
                            className="clinician-input"
                            type="text"
                            id="fname"
                            name="FirstName"
                            dataTestId="fname_field"
                            placeholder="First Name"
                            value={practiceStaffMember?.staffInfo.firstName}
                            disabled={true}
                        />
                        <Input
                            className="clinician-input"
                            type="text"
                            id="lname"
                            name="LastName"
                            dataTestId="lname_field"
                            placeholder="Last Name"
                            value={practiceStaffMember?.staffInfo.lastName}
                            disabled={true}
                        />
                        <Input
                            className="clinician-input-title"
                            type="text"
                            id="title"
                            name="Title"
                            dataTestId="title"
                            placeholder="Title"
                            value={practiceStaffMember?.staffInfo.title}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            DOB:
                        </Label>
                        <Label
                            className="clinician-label-gender"
                            htmlFor="html">
                            Gender:
                        </Label>
                        <SelectDropdown
                            value={practiceStaffMember?.staffInfo.gender}
                            className="clinician-dropdown"
                            id="Gender"
                            name="Gender"
                            disabled={true}
                        >
                            <option value={0}>Male</option>
                            <option value={1}>Female</option>
                        </SelectDropdown>
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            NPI:
                        </Label>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            {practiceStaffMember?.staffInfo?.npi}
                        </Label>
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Role:
                        </Label>
                        <SelectDropdown
                            id="Role"
                            value={practiceStaffMember?.staffInfo.role}
                            name="Role"
                            className="clinician-dropdown"
                            disabled={true}
                        >
                        <option value={0}>None</option>
                        <option value={1}>Physician</option>
                        <option value={2}>Clinical</option>
                        <option value={3}>Business</option>
                        <option value={4}>Billing</option>
                        <option value={5}>Employee</option>
                        <option value={6}>Associates</option>
                        <option value={7}>Resident</option>
                        </SelectDropdown>
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Phone:
                        </Label>
                        <Input
                            className="clinician-input"
                            type="text"
                            id="name"
                            name="name"
                            dataTestId="name_field"
                            placeholder="Phone Number"
                            value={practiceStaffMember?.staffInfo?.phone}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Pager:
                        </Label>
                        <Input
                            className="clinician-input"
                            type="text"
                            id="pager"
                            name="PagerNo"
                            dataTestId="pager"
                            placeholder="PagerNo"
                            value={practiceStaffMember?.staffInfo.pager}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    <div className='staff-phone-fax-wrapper'>
                        <div className='clinician-row'>
                            <Label
                                className="clinician-label"
                                htmlFor="html">
                                Staff Phone:
                            </Label>
                            <Input
                                className="clinician-input"
                                type="text"
                                id="staff-phone"
                                name="StaffPhone"
                                dataTestId="staff-phone"
                                placeholder="StaffPhone"
                                value={practiceStaffMember?.staffInfo.staffPhone}
                                disabled={true}
                            />
                        </div>
                        <div className='clinician-row'>
                            <Label
                                className="clinician-label-fax"
                                htmlFor="html">
                                Fax:
                            </Label>
                            <Input
                                className="clinician-input"
                                type="text"
                                id="fax"
                                name="FaxNo"
                                dataTestId="fax"
                                placeholder="FaxNo"
                                value={practiceStaffMember?.staffInfo.fax}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Email:
                        </Label>
                        <Input
                            className="clinician-input-email"
                            type="Email"
                            id="email"
                            name="EmailAddress"
                            dataTestId="email"
                            placeholder="EmailAddress"
                            value={practiceStaffMember?.staffInfo.emailAddress}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row-sqcn'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            SQCN:
                        </Label>
                        <Checkbox
                            id="SQCN"
                            name="SQCN"
                            className="form-check-input mt-0"
                            type="checkbox"
                            dataTestId="SQCN"
                            checked={practiceStaffMember?.staffInfo.sqcnmember}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label-pcp"
                            htmlFor="html">
                            Provider PCP:
                        </Label>
                        <Checkbox
                            id="ProviderPCP"
                            name='ProviderPCP'
                            className="form-check-input mt-0"
                            type="checkbox"
                            dataTestId="provider-pcp"
                            checked={practiceStaffMember?.staffInfo.providerPcpflag}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                </div>
            )}
            <div className='image-upload-wrapper'>
                <UploadImage staffId={staffId} photo={practiceStaffMember?.physicianInfo?.photo ?? ""} />
            </div>
        </div>
    );
};
export default ClinicianInfo;