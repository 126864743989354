import React, { useEffect, useState } from 'react'
import { Button, ModalOverlayWithoutClose } from "@sentaraui/optimahealth_web";
import "./PracticesListsStyle.scss";
import ConfirmationModal from '../modal/confirmationModal';
import { useRemoveLocationMutation, useGetAddPracticesQuery } from '../services/practicesApi';
import { Practice, PracticeListProps, IPracticeLocation } from '../interface/PracticesLists';
import LocationComponent from '../location/locationComponent';
import { initialLocationState } from '../../constants/constants';
import AuthoritativeSourceComponent from '../authoritativeSource/authoritativeSource';
import AddPracticeModal from '../addPractice/addPracticeModal';
import EditPracticeComponent from './edit/editPracticeComponent';
import { usePracticeViewModelDeleteQuery } from '../hooks/usePractice';
import ToastNotification from '../common/toast/toastNotification';
import { ILocation } from '../models/location/location';
import Table from '../common/Table/table';
import { HandleErrorMessage } from '../common/errorMessage';

const PracticesLists: React.FC<PracticeListProps> = ({ practices, sendValueToParent, btnFlag,
    refetchPractices, selectedPracticeId, setSelectedPracticeId, setSelectedPracticeFromChild, setFire, fire }) => {

    const [context, setContext] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLocationModalOpen, setIsLocationModalOpen] = useState<boolean>(false);
    const [practiceModalOpen, setPracticeModalOpen] = useState<boolean>(false);
    const [selectedPractice, setSelectedPractice] = useState<number>(0);
    const [selectedLocation, setSelectedLocation] = useState<{ practiceId: number; locationId: number }>({ practiceId: 0, locationId: 0 });
    const [selectedLocationDetails, setSelectedLocationDetails] = useState<ILocation>(initialLocationState);
    const [isEdit, setIsEdit] = useState<boolean>(true);
    const [removeLocation] = useRemoveLocationMutation();
    const [selectedPracticeObject, setSelectedPracticeObject] = useState<Practice>({} as Practice);
    const [authoritativeSourcePracticeId, setAuthoritativeSourcePracticeId] = useState<number | null>(null);
    const [authoritativeSourcePracticeName, setAuthoritativeSourcePracticeName] = useState<string | null>(null);
    const [isAuthoritativeModalOpen, setIsAuthoritativeModalOpen] = useState(false);
    const { removePractice } = usePracticeViewModelDeleteQuery(selectedPracticeId);
    const [isPracticeModalOpen, setIsPracticeModalOpen] = useState(false);
    const [locationDetails, setLocationDetails] = useState<ILocation>({} as ILocation);
    const [triggerApi, setTriggerApi] = useState(false);
    const { data: canAddPracticeData, refetch } = useGetAddPracticesQuery(undefined, {
        skip: !triggerApi,
    });

    useEffect(() => {
        if (canAddPracticeData !== undefined && triggerApi) {
            if (canAddPracticeData) {
                setIsPracticeModalOpen(true);
            } else {
                ToastNotification('info', "You do not have permission to add a practice.");
            }
            setTriggerApi(false);  // Reset trigger to avoid repeated API calls
            refetch();
        }
    }, [canAddPracticeData, triggerApi, refetch]);


    const tableHeader = [
        {
            text: 'Location'
        },
        {
            text: 'Address'
        },
        {
            text: 'City'
        },
        {
            text: 'St'
        },
        {
            text: 'Zip'
        },
        {
            text: 'Phone'
        },
        {
            text: 'Fax'
        }
    ];

    const highlightTableRow = (row: HTMLTableRowElement, outerTableRowIndex?: number) => {
        // Remove highlight from previously clicked-row row, if any
        const previouslySelectedRow = document.querySelector('.inner-table1 tr.clicked-row');
        if (previouslySelectedRow) {
            previouslySelectedRow.classList.remove('clicked-row');
        }

        // Highlight the clicked row
        row.classList.add('clicked-row');
    };

    const clearHighlightRow = () => {
        const previousOuterRow = document.querySelector('.outer-table tr.clicked-outer-row');

        if (previousOuterRow) {
            previousOuterRow.classList.remove('clicked-outer-row');
        }

        const previouslyInnerRow = document.querySelector('.inner-table1 tr.clicked-row');
        if (previouslyInnerRow) {
            previouslyInnerRow.classList.remove('clicked-row');
        }

    }
    const highlightOuterTableRow = (row: HTMLTableRowElement) => {
        // Remove highlight from previously clicked-row row, if any
        const previouslySelectedRow = document.querySelector('.outer-table tr.clicked-outer-row');

        if (previouslySelectedRow) {
            previouslySelectedRow.classList.remove('clicked-outer-row');
        }

        // Highlight the clicked row
        row.classList.add('clicked-outer-row');
    };

    // Function to handle outer table row selection
    const handleOuterRowSelect = (practiceName: string) => {
        const outerTablerows = document.getElementsByClassName('outer-table')[0]?.getElementsByTagName('tr');

        for (let i = 1; i < outerTablerows.length; i++) {
            const outerTableRow = outerTablerows[i];
            if (outerTableRow.cells[0].innerText === (practiceName ?? '')) {
                highlightOuterTableRow(outerTableRow);
                break;
            }
        }
    };


    // Function to handle inner table row selection
    const handleSelectedRowColorChange = (innerTableRowIndex: number, practiceId: number, location: IPracticeLocation, outerTableRowIndex?: number) => {
        let classNameInner = `inner-table_${practiceId}`
        const innerTableRows = document.getElementsByClassName(classNameInner)[0]?.getElementsByTagName('tr');

        // Ensure rows are not null
        if (!innerTableRows) return;

        // Loop through the outer table rows
        for (let i = 1; i < innerTableRows.length; i++) {
            const innerTableRow = innerTableRows[i];
            if (innerTableRow.cells[0].innerText === (location?.locationName ?? '')) {
                highlightTableRow(innerTableRow, outerTableRowIndex);
                break;
            }
        }
    };

    const handlePracticedata = (practice: Practice) => {
        clearHighlightRow();
        const flag = 'practice';
        sendValueToParent(practice.practiceName);
        setContext(flag);
        setSelectedPractice(practice.practiceId); // Save the clicked-row practice's id
        setSelectedPracticeObject(practice); // Save the clicked-row practice object
        setSelectedLocation({ locationId: 0, practiceId: 0 }); // Reset the clicked-row location
        setAuthoritativeSourcePracticeId(practice.practiceId);
        setAuthoritativeSourcePracticeName(practice.practiceName);
        setSelectedPracticeId(practice.practiceId);
        setSelectedPracticeFromChild(practice);
        handleOuterRowSelect(practice.practiceName)
    }

    const handleLocationdata = (practiceId: number, practiceName: string, location: IPracticeLocation, practice: Practice, innerRowIndex: number, outerRowIndex: number,) => {
        clearHighlightRow();
        const flag = 'location';
        sendValueToParent(location.locationName);
        setContext(flag);
        setSelectedLocation({ practiceId, locationId: location.locationId }); // Save the clicked-row location's id and the id of its parent practice
        setSelectedLocationDetails({ ...initialLocationState, locationID: location.locationId, practiceID: practiceId, name: location.locationName }); // Save the clicked-row location details
        setSelectedPractice(0); // Reset the clicked-row practice
        setSelectedPracticeId(practiceId);
        setAuthoritativeSourcePracticeId(practiceId);
        setAuthoritativeSourcePracticeName(practiceName);
        setSelectedPracticeFromChild(practice);
        handleSelectedRowColorChange(innerRowIndex, practiceId, location, outerRowIndex);
        setLocationDetails((prev: ILocation) => {
            if (selectedLocation) {
                return {
                    ...prev,
                    practiceID: selectedLocation.practiceId,
                    locationID: selectedLocation.locationId,
                };
            }
            return prev;
        });


    };

    const handleRemove = () => {
        if (shouldShowModal()) {
            setShowModal(true);
            setIsModalOpen(true);
        }
    };

    const shouldShowModal = () => {
        return (selectedLocation?.locationId > 0 && selectedLocation.practiceId > 0) || selectedPractice > 0;
    };

    const getButtonLabels = () => {
        switch (context) {
            case 'practice':
                return { edit: 'Edit Practices', remove: 'Remove Practices' };
            case 'location':
                return { edit: 'Edit Location', remove: 'Remove Location' };
            default:
                return { edit: 'Edit', remove: 'Remove' };
        }

    }
    const { edit, remove } = getButtonLabels();

    // Hander for open the location popup
    const handleLocationModalOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (selectedPractice <= 0 && selectedLocation.practiceId <= 0) return;
        const buttonLabel = event.currentTarget.innerText;
        if (buttonLabel === 'Edit Location' && (selectedLocationDetails?.locationID || locationDetails?.locationID)) {
            setIsEdit(true);
            setIsLocationModalOpen(true);
            if (selectedLocationDetails?.locationID === 0) {
                setSelectedLocationDetails(locationDetails)
            }
        } else if (buttonLabel === 'Add Location') {
            setIsEdit(false);
            if (selectedPracticeObject?.practiceId || selectedLocationDetails?.practiceID) {
                const updatedLocationState = {
                    ...initialLocationState,
                    practiceID: selectedPracticeObject?.practiceId || selectedLocationDetails?.practiceID,
                    type: '1',
                }
                setSelectedLocationDetails(updatedLocationState);
                setIsLocationModalOpen(true);
            };
        }
    }
    const handleEditPracticeModal = () => {
        setPracticeModalOpen(true);
    }

    const handleCloseModal = () => {
        setSelectedPracticeFromChild(null);
        setIsLocationModalOpen(false);
        setPracticeModalOpen(false);
        setIsEdit(false);
        if (isEdit) {
            setLocationDetails(selectedLocationDetails);
        }
    }

    const closeModal = () => {
        setIsAuthoritativeModalOpen(false);
    }

    const handleAddPracticeClick = () => {
        setTriggerApi(true);
    }
    const getModalHeader = () => {
        if (context === 'practice') {
            return 'Confirm Remove practice?';
        } else if (context === 'location') {
            return 'Confirm Remove location?';
        } else {
            return '';
        }
    };

    const getModalMessage = () => {
        if (context === 'practice' && selectedPracticeObject) {
            return `Are you sure you want to remove ${selectedPracticeObject.practiceName}?`;
        } else if (context === 'location' && selectedLocationDetails && selectedPracticeObject) {
            return `Are you sure you want to remove ${selectedLocationDetails.name} from ${authoritativeSourcePracticeName}?`;
        } else {
            return '';
        }
    };

    // Clear context when practices are refetched
    useEffect(() => {
        setContext('');
    }, [practices]);

    const handleSelectedLocationRemove = async (selectedLocation: any) => {
        try {
            const response = await removeLocation(selectedLocation).unwrap();
            ToastNotification('success', response);
            setFire(false);
            setSelectedLocation({ locationId: 0, practiceId: 0 });
        } catch (error: unknown) {
            if (error instanceof Error) {
                HandleErrorMessage(error, 'remove practice location');
            } else {
                HandleErrorMessage(new Error('An unknown error occurred'), 'remove practice location');
            }
        }
    }

    const handleSelectedPracticeRemove = async (selectedPractice: any) => {
        try {
            const respone: any = await removePractice(selectedPractice).unwrap();
            ToastNotification('success', respone);
            setFire(false);
            setSelectedPractice(0);
        } catch (error: unknown) {
            if (error instanceof Error) {
                HandleErrorMessage(error, 'remove practice');
            } else {
                HandleErrorMessage(new Error('An unknown error occurred'), 'remove practice');
            }
        }
    }
    return (
        <>
            <div className='row' id='container'>
                <div id='table-content'>
                    <Table
                        className="outer-table"
                        header={[
                            {
                                text: 'Practice Name'
                            },
                            {
                                text: 'Location'
                            },
                            {
                                text: <div onClick={handleAddPracticeClick}><span className='addIcon'> &#10010;</span> </div>
                            }
                        ]}

                        rows={practices.map((practice: Practice, outerRowIndex: number) => [
                            {
                                text: <td className={`practice-item ${selectedPractice === practice.practiceId ? 'selected' : ''}`} onClick={() => handlePracticedata(practice)}> {practice.practiceName} </td>
                            },
                            {
                                colspan: 2,
text: (
    <td>
        <Table
            className={`inner-table1 inner-table_${practice.practiceId}`}
            header={tableHeader}
            rows={practice.locations.map((location: IPracticeLocation) => [
                { text: <td id="locationName">{location.locationName}</td> },
                { text: <td id="address">{location.address1 || ''}</td> },
                { text: <td id="city">{location.city}</td> },
                { text: <td id="state">{location.state !== "" && location.state !== null ? location.state.toUpperCase() : ""}</td> },
                { text: <td id="postalCode">{location.postalCode}</td> },
                { text: <td id="phone">{location.phoneNumber}</td> },
                { text: <td id="fax">{location.faxNumber}</td> },
            ])}
            rowClickHandler={(innerRowIndex) => {
                const selectedLocation = practice.locations?.[innerRowIndex];

                if (selectedLocation) {
                    handleLocationdata(
                        practice.practiceId,
                        practice.practiceName,
                        selectedLocation,
                        practice,
                        innerRowIndex,
                        outerRowIndex
                    );
                }
            }}
        />
    </td>
)

                            }
                        ])}
                    />
                    <div className='footer-buttons'>
                        {(context === 'location' || context === 'practice') && practices.length > 0 && (
                            <div id='div-footer'>
                                <Button className='btn-footer' dataTestId='remove' onClick={handleRemove}>{remove}</Button>
                                <Button className='btn-footer' dataTestId='edit' onClick={context === 'practice' ? handleEditPracticeModal : handleLocationModalOpen}>{edit}</Button>
                                <Button className='btn-footer' dataTestId='add' onClick={handleLocationModalOpen}>Add Location</Button>
                            </div>
                        )}
                        {context === '' && practices.length > 0 && (
                            <div id='div-footer'>
                                <Button className='disabled-btn-footer' dataTestId='remove' disabled='true' onClick={handleRemove}>{remove}</Button>
                                <Button className='disabled-btn-footer' dataTestId='edit' disabled='true' onClick={handleLocationModalOpen}>{edit}</Button>
                                <Button className='disabled-btn-footer' dataTestId='add' disabled='true' onClick={handleLocationModalOpen}>Add Location</Button>
                            </div>
                        )}
                        {btnFlag && practices.length === 0 && (
                            <div id='div-footer'>
                                <Button className='disabled-btn-footer' dataTestId='remove' disabled='true' onClick={handleRemove}>{remove}</Button>
                                <Button className='disabled-btn-footer' dataTestId='edit' disabled='true' onClick={handleLocationModalOpen}>{edit}</Button>
                                <Button className='disabled-btn-footer' dataTestId='add' disabled='true' onClick={handleLocationModalOpen}>Add Location</Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ModalOverlayWithoutClose isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {showModal &&
                    <ConfirmationModal
                        header={getModalHeader()}
                        message={getModalMessage()}
                        onConfirm={() => {
                            setShowModal(false);
                            setIsModalOpen(false);
                            if (context === 'practice' && selectedPractice && selectedPractice > 0) {
                                handleSelectedPracticeRemove(selectedPractice);
                            } else if (context === 'location' && selectedLocation && selectedLocation.locationId > 0) {
                                handleSelectedLocationRemove(selectedLocation);
                            }
                        }}
                        onCancel={() => {
                            setShowModal(false);
                            setIsModalOpen(false);
                        }}
                        confirmText='Confirm'
                        cancelText='Cancel' />}
            </ModalOverlayWithoutClose>
            {practices.length === 0 && (
                <div className='row' id='footer-text'>
                </div>
            )}
            <ModalOverlayWithoutClose
                className='location-modal'
                isOpen={isLocationModalOpen}
                onClose={handleCloseModal}
            >
                <div className='location-container'>
                    <div className='top-container'>
                        <div className='top-title-container'>{isEdit
                            ? `Edit Location: ${selectedLocationDetails?.name}`
                            : `Add Location: ${selectedLocationDetails?.name || ''}`}
                        </div>
                        <button onClick={handleCloseModal}>x</button>
                    </div>
                    <div>
                        <LocationComponent
                            isEdit={isEdit}
                            selectedLocationDetails={selectedLocationDetails}
                            setSelectedLocationDetails={setSelectedLocationDetails}
                            handleClose={handleCloseModal}
                            onLocationSave={() => { }}
                            setFire={setFire}
                            fire={fire}
                        />
                    </div>
                </div>
            </ModalOverlayWithoutClose>
            <ModalOverlayWithoutClose
                className='practice-modal'
                isOpen={practiceModalOpen}
                onClose={handleCloseModal}
            >
                <div className='practice-container'>
                    <div className='top-container'>
                        <div className='top-title-container'>{
                            `Edit: ${selectedPracticeObject?.practiceName}`}
                        </div>
                        <button onClick={handleCloseModal}>x</button>
                    </div>
                    <div>
                        <EditPracticeComponent
                            practiceId={selectedPracticeObject?.practiceId}
                            handleCloseModal={handleCloseModal} setFire={setFire}
                        />
                    </div>
                </div>
            </ModalOverlayWithoutClose>
            <ModalOverlayWithoutClose isOpen={isAuthoritativeModalOpen} onClose={() => setIsAuthoritativeModalOpen(false)}>
                <AuthoritativeSourceComponent practiceId={authoritativeSourcePracticeId} practiceName={authoritativeSourcePracticeName} onClose={closeModal} />
            </ModalOverlayWithoutClose>
            <ModalOverlayWithoutClose isOpen={isPracticeModalOpen} onClose={() => setIsPracticeModalOpen(false)}>
                <AddPracticeModal closeModal={() => setIsPracticeModalOpen(false)} setFire={setFire} fire={fire} />
            </ModalOverlayWithoutClose>
        </>
    )
}
export default PracticesLists