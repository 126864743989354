import React from 'react';
import './editPracticeStyles.scss';
import {
    Input,
    Label,
    SelectDropdown,
} from "@sentaraui/optimahealth_web";
import { IEditPractice } from '../../../interface/Practice';
import { useEditPracticeViewModel } from './useEditPracticeViewModel';
import { SuperGroupItem } from '../../../interface/PracticesLists';

interface EditPracticeProps {
    editPracticeData: IEditPractice | null;
    setEditPracticeData: (editPracticeData: IEditPractice) => void;
}

const EditPractice: React.FC<EditPracticeProps> = ({
    editPracticeData,
    setEditPracticeData
}) => { 
    const {
        practiceTypes,
        practiceType,
        selectedSuperGroup,
        superGroupData,
        handleInputChange,
        handleSelectChange,
        handlePracticeTypeChange,
    } = useEditPracticeViewModel({ editPracticeData, setEditPracticeData });
    return (
        <div className='edit-practice-wrapper'>
            <div className='edit-practice-row'>
                <Label
                    className="edit-practice-label"
                    htmlFor="superGroup">
                    Super Group
                </Label>
                <SelectDropdown
                    id="superGroup"
                    value={selectedSuperGroup} // ToDo: Implement this
                    onChange={handleSelectChange}
                    className="edit-practice-dropdown"
                >
                    {superGroupData.map((group: SuperGroupItem) => (
                        <option key={group.superGroupId} value={group.superGroupId}>
                            {group.superGroupName}
                        </option>
                    ))}
                </SelectDropdown>
            </div>
            <div className='edit-practice-row'>
                <Label
                    className="edit-practice-label"
                    htmlFor="practiceType">
                    Type
                </Label>
                <SelectDropdown
                    id="practiceType"
                    value={practiceType}
                    onChange={handlePracticeTypeChange}// ToDo: Implement this
                    className="edit-practice-dropdown"
                >
                    {practiceTypes.map(type => (
                        <option key={type.value} value={type.label}>
                            {type.label}
                        </option>
                    ))}
                </SelectDropdown>
            </div>
            <div className='edit-practice-row'>
                <Label
                    className="edit-practice-label"
                    htmlFor="name">
                    Practice Name
                </Label>
                <Input
                    className="edit-practice-input"
                    type="text"
                    id="name"
                    name="name"
                    dataTestId="input_field"
                    onChange={handleInputChange}
                    placeholder="Practice Name"
                    value={editPracticeData?.name ?? ''}
                />
            </div>
            <div className='edit-practice-row'>
                <Label
                    className="edit-practice-label"
                    htmlFor="taxID">
                    Tax ID
                </Label>
                <Input
                    className="edit-practice-input"
                    type="text"
                    id="taxId"
                    name="taxID"
                    dataTestId="input_field"
                    onChange={handleInputChange}
                    placeholder="Tax ID"
                    value={editPracticeData?.taxID ?? ''}
                />
            </div>
            <div className='edit-practice-row'>
                <Label
                    className="edit-practice-label"
                    htmlFor="vendorIds">
                    Vendor IDs
                </Label>
                <Input
                    className="edit-practice-input"
                    type="text"
                    id="vendorIds"
                    name="vendorIds"
                    dataTestId="input_field"
                    onChange={handleInputChange}
                    placeholder="Vendor IDs"
                    value={editPracticeData?.vendorIds ?? ''}
                />
            </div>
        </div>
    )
};
export default EditPractice;