
import { useEffect } from "react";
import { useUploadPictureMutation } from '../../services/staffApi';
import ToastNotification from "../../common/toast/toastNotification";
import { HandleErrorMessage } from "../../common/errorMessage";

export const useUploadImageHook  = (setIsSpiner:(value:boolean) => void) => {
    const [uploadPicture, {error, data, isSuccess }] = useUploadPictureMutation();
    useEffect(() => {
        if (error) {
            HandleErrorMessage(error);
            setIsSpiner(false);
        }
    }, [error, setIsSpiner]);
    useEffect(() => {
        if (isSuccess) {
            ToastNotification("success",(data as any));
            setIsSpiner(false);

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, setIsSpiner]);
    return  {uploadPicture} ;
}