import React, { useEffect } from 'react';
import './clinicianInfoStyles.scss';
import { Input, Label } from "@sentaraui/optimahealth_web";
import { useImageViewModel } from './useImageViewModel';
import Loader from '../../common/spinner/loader';

interface UploadImageProps {
    staffId: number;
    photo: string;
}

const UploadImage: React.FC<UploadImageProps> = ({ staffId, photo }) => {
    const {
        handleUploadClick,
        handleImageChange,
        disableUpload,
        imageUploadSuccess,
        setImageUploadSuccess,
        imageRestored,
        selectedImage,
        isSpinner
    } = useImageViewModel(staffId, photo);

    const resetFileInput = () => {
        const fileInput = document.getElementById('clinician-input-image') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    useEffect(() => {
        if (imageUploadSuccess === true || imageRestored === true) {
            resetFileInput();
        }

        if (imageUploadSuccess) {
            const timer = setTimeout(() => {
                setImageUploadSuccess(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [imageUploadSuccess, imageRestored, setImageUploadSuccess]);

    return (
        <div className='image-upload-wrapper'>
            {selectedImage && (
                <div className="uploaded-image-preview">
                    <img
                        src={`data:image/jpeg;base64,${selectedImage}`}
                        alt="Uploaded preview"
                        className="clinical-selected-image"
                    />
                </div>
            )}
            <div className='image-upload' data-testId="image-upload">
                <div>
                    <Label className="clinical-image-label" dataTestId="image-options-label" htmlFor="html">
                        Image Options
                    </Label>
                </div>
                <hr />
                <div>
                    <button className="clinical-image-button-wrapper" title='Upload'
                        data-testid="upload-button"
                        onClick={(event: any) => handleUploadClick('upload', event)}
                        disabled={disableUpload}>
                        <span className="clinical-image-upload-button"></span>
                    </button>
                    <button className="clinical-image-button-wrapper" title='Restore'
                        data-testid="restore-button"
                        onClick={(event: any) => handleUploadClick('restore', event)}
                        disabled={disableUpload}>
                        <span className="clinical-image-restore-button"></span>
                    </button>
                    <button className="clinical-image-button-wrapper" title='Delete'
                        data-testid="delete-button"
                        onClick={(event: any) => handleUploadClick('delete', event)}>
                        <span className="clinical-image-delete-button"></span>
                    </button>
                </div>
                <hr />
                <div>
                    <Input
                        accept=".jpeg, .jpg, .png"
                        className="clinician-input-image"
                        type="file"
                        id="clinician-input-image"
                        name="clinician-input-image"
                        dataTestId="clinician-input-image"
                        placeholder="Upload Image"
                        onChange={(e: any) => handleImageChange(e)}
                    />
                    {imageUploadSuccess && <span>Image is successfully uploaded</span>}
                </div>
            </div>
            {isSpinner && <Loader />}
        </div>
    );
};

export default UploadImage;
