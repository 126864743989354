import React from "react";
import "./hospitalRolesStyles.scss";
import { Table } from "@sentaraui/optimahealth_web";
import { IHospitalRole, IPracticeStaffMember } from "../../interface/staff";
import { useHospitalRolesViewModel } from "./hospitalRolesViewModel";

interface IHospitalRolesProps {
    practiceStaffMember: IPracticeStaffMember | null;
}

// Define the table headers outside of the component
const TABLE_HEADERS = [
    { text: 'Hospital' },
    { text: 'Code' },
    { text: 'Start' },
    { text: 'End' }
];

const HospitalRoles: React.FC<IHospitalRolesProps> = ({ practiceStaffMember }) => {
    const { hospitalRoles } = useHospitalRolesViewModel({ practiceStaffMember });
    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    return (
        <Table
            className='hospital-table'
            header={TABLE_HEADERS}
            rows={hospitalRoles?.map((role: IHospitalRole) => [
                { text: role.description },
                { text: role.code },
                { text: role.startDate ? formatDate(role.startDate.toString()) : '' },
                { text: role.endDate  ? formatDate(role.endDate.toString()) : ''  }
            ])}
        />
    );
};

export default HospitalRoles;