import { useState } from "react";
import { useGetSpecialtiesInfoQuery } from "../../../services/staffApi";
export const useSpecialitiesViewModel = (personId: number) => {
    const [isVisible, setIsVisible] = useState(false);
    const [triggerQuery, setTriggerQuery] = useState(false);
    const { data } = useGetSpecialtiesInfoQuery({ personId: personId }, { skip: !triggerQuery });
    const handlePopUp = () => {
        setIsVisible(!isVisible);
        if (!isVisible) {
            setTriggerQuery(true);
        }
    };
    const formatDate = (date: Date | string | null): string => {
        if (!date) return "";
        const d = new Date(date);
        const day = d.getDate();
        const month = d.getMonth() + 1; // Months are zero-based
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };
    const getSpecialtyValues = (specialty: any) => [
        specialty.description,
        specialty.specialtyStatusDesc,
        formatDate(specialty.startDate).toString(),
        formatDate(specialty.endDate).toString(),
        specialty.certified,
        specialty.primary ? 'Yes' : 'No',
        specialty.practicing ? 'Yes' : 'No',
        specialty.hospitalList ? 'Yes' : 'No',
        specialty.facility
    ];
    const colHeaders = [
        { value: 'Type'},
        { value: 'Status'},
        { value: 'Start' },
        { value: 'End'},
        { value: 'Certified'},
        { value: 'Primary'},
        { value: 'Practicing'},
        { value: 'HospitalList'},
        { value: 'Facility'}
    ]
    return {
        isVisible,
        colHeaders,
        data,
        handlePopUp,
        getSpecialtyValues,
        formatDate
    };
};