import React, {  useMemo, useState } from 'react'
import './homeStyle.scss'
import SearchForm from '../searchform/SearchForm';
import PracticesLists from '../practiceslists/PracticesLists';
import { Filters } from '../interface/Filter';
import { StaffScreen } from '../staffScreen/staffScreen';
import { useAutoCompleteStateViewModel } from '../hooks/useAutocomplete';
import { IAutoCompleteItem } from '../interface/autocompleteModel';
import { usePracticeViewModelQuery } from '../hooks/usePractice';
import Loader from '../common/spinner/loader';
import { ISearchFilter, IStaffSearch } from '../interface/staff';
import { Practice } from '../interface/PracticesLists';

let OPTION: IAutoCompleteItem[] = [];

const Home = () => {
    const [formData, setFormData] = useState({
        practiceName: '',
        locationName: '',
        locationCity: '',
        locationState: '',
        locationZip: ''
    });
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const [fire,setFire] = useState<boolean>(true);
    const  autoCompleteStateData: IAutoCompleteItem[] = useAutoCompleteStateViewModel().data || [];
    const [filters, setFilters] = useState<Filters>({ practiceName: '', locationName: '', locationCity: '', locationState: '', locationZip: '' });
    const { data, error, isLoading, isSuccess, refetch } =  usePracticeViewModelQuery( filters, fire, setFire );
    const [changeTab, setChangeTab] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const [filteredPractices, setFilteredPractices] = useState<Practice[]>(data || []);
    const [ searchbtnClick, setSearchbtnClick] = useState(false);
    const [searchResults, setSearchResults] = useState<IStaffSearch[]>([]);
    const [selectedPracticeId, setSelectedPracticeId] = useState<number | null>(null);
    const [searchCriteria, setSearchCriteria] = useState<ISearchFilter>({
        lastName: '',
        firstName: '',
        dob: '',
        ssn: '',
        practiceName: '',
        practiceId: '',
        locationId: "",
      });
    const [selectedPracticeFromChild, setSelectedPracticeFromChild] = useState<Practice | null>(null);
    const[isSkip, setIsSkip]= useState<boolean>(true);
    const handleValueFromChild = (value: string) => {
        setSelectedValue(value);
    };
    const handleValueofSearchBtn = (value: string) => {
        setSearchbtnClick(true);
    }
    const handleStaffTab = () => {
        setChangeTab(true);
        if (selectedPracticeId !== null && selectedPracticeId >0) {
            setIsSkip(false);
        }
    };

    const handlePracticeTab = () => {
        setChangeTab(false);

    }

    useMemo(() => {
        setSearchCriteria(prevCriteria => ({
                    ...prevCriteria,
                    practiceId: selectedPracticeId ?? '',
                    lastName: selectedPracticeId ? '' : prevCriteria.lastName,
                    firstName: selectedPracticeId ? '' : prevCriteria.firstName,
                    dob: selectedPracticeId ? '' : prevCriteria.dob,
                    ssn: selectedPracticeId ? '' : prevCriteria.ssn,
                }));
        if(isSuccess)
        {
            setFilteredPractices(data || []);
            setFire(true);
            setIsSpinner(false);
        }
        if(isLoading){
            setIsSpinner(true);
        }
        if(error){
            console.log('Error while fetching practices', error);
            setIsSpinner(false);
        }
    },[isSuccess, isLoading, error, selectedPracticeId, data, setSearchCriteria, setFire] );

    const handleSearch = async (newFilters: Filters) => {
        setFilters(newFilters);
        setFire(false);
    };

    return (
        <div className='container' id='main-container'>
            {changeTab ?
                <div className='row' id='main-content'>
                    <div className='col-1' id='left-tab-staff' onClick={handlePracticeTab}>
                        <div id='left-tab-text-staff'>
                            Practices
                        </div>
                    </div>
                    <div className='col-1' id='right-tab-staff' onClick={handleStaffTab}>
                        <div id='right-tab-text-staff'>
                            {selectedValue ? `Staff: ${selectedValue}` : 'Staff'}
                        </div>
                    </div>
                    <div className='col-10' id='middle-tab'>
                    <StaffScreen searchResults={searchResults} setSearchResults={setSearchResults}
                    practiceId={selectedPracticeId} selectedPractice={selectedPracticeFromChild}  isSkip ={isSkip} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria}/>
                    </div>
                </div>
                :
                <div className='row' id='main-content'>
                    <div className='col-1' id='left-tab' onClick={handlePracticeTab}>
                        <div id='left-tab-text'>
                            Practices
                        </div>
                    </div>
                    <div className='col-10' id='middle-tab'>
                        <SearchForm
                            onSearch={handleSearch}
                            sendValueToHome={handleValueofSearchBtn}
                            autoCompleteStateData={autoCompleteStateData}
                            OPTION={OPTION}
                            formData={formData}
                            setFormData={setFormData}
                            />
                        <PracticesLists practices={filteredPractices} sendValueToParent={handleValueFromChild}
                         btnFlag ={searchbtnClick} refetchPractices={refetch} selectedPracticeId={selectedPracticeId}
                         setSelectedPracticeId={setSelectedPracticeId}
                         setSelectedPracticeFromChild={setSelectedPracticeFromChild} 
                         setFire={setFire}
                         fire={fire}
                         />
                    </div>
                    <div className='col-1' id='right-tab' onClick={handleStaffTab}>
                        <div id='right-tab-text'>
                            {selectedValue ? `Staff: ${selectedValue}` : 'Staff'}
                        </div>
                    </div>
                </div>}
                {isSpinner && <Loader />}
        </div>
    )
}

export default Home